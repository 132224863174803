import { useEffect } from "react";
import { mixpanel } from "Utils/mixpanel";

type AnalyticsEventKeys =
  | "login"
  | "login_success"
  | "login_failed"
  | "create_ticket"
  | "team_ticket"
  | "create_ticket_from_team";
export const addMixpanelEvent = ({
  event,
  data,
}: {
  event: AnalyticsEventKeys;
  data?: Record<string, any>;
}) => {
  // if (process.env.NODE_ENV === "UAT" || process.env.NODE_ENV === "PROD") {
  mixpanel.track(event, data);
  // }
};

export const useMixpanelPageViewTracker = ({
  event,
  data,
}: {
  event: AnalyticsEventKeys;
  data?: Record<string, any>;
}) => {
  useEffect(() => {
    // if (process.env.NODE_ENV === "UAT" || process.env.NODE_ENV === "PROD") {
    mixpanel.track(event, data);
    // }
  }, []);
};
