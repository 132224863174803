export const generateUrlString = async ({
  baseUrl,
  data,
}: {
  baseUrl: string;
  data: Record<string, string>;
}) => {
  const query = new URLSearchParams(data);
  return `${baseUrl}?${query.toString()}`;
};
