import { Body1 } from "@fluentui/react-components";
import { debugConsole } from "Utils";
import { Box, Modal } from "components";
import { useGlobalStore, useLogout, useRemoveCustomerUser } from "controller";
import { useState } from "react";
import toast from "react-hot-toast";

export const LogoutModal = ({
  isOpen,
  onClose,
  aadId,
  customerUserId,
  token,
}: {
  onClose: () => void;
  isOpen: boolean;
  aadId: string;
  customerUserId: string;
  token: string;
}) => {
  const { mutateAsync: removeCustomerUser } = useRemoveCustomerUser();
  const resetProfile = useGlobalStore((state) => state.resetProfileData);
  const { mutateAsync: logoutUser } = useLogout();
  const [isLoading, setIsLoading] = useState(false);
  const signoutHandler = async () => {
    try {
      setIsLoading(true);
      try {
        await logoutUser(token);
      } catch (e: any) {
        if (e?.message !== "UNAUTHERIZED") {
          return;
        }
      }
      await removeCustomerUser({
        aadId: aadId,
        customerUserId: customerUserId,
      });
      resetProfile();
      onClose();
      setTimeout(() => {
        window.location.reload();
      }, 100);
    } catch (e: any) {
      console.log("🚀 ~ signoutHandler ~ e:", e);
      toast.error("Unable to signout at the moment");
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <Modal
      open={isOpen}
      onClose={() => onClose()}
      title="Sign out"
      primaryButtonLabel="Sign out"
      secondaryButtonLabel="Cancel"
      onPrimaryButtonClick={signoutHandler}
      onSecondaryButtonClick={() => onClose()}
      isLoading={isLoading}
    >
      <Body1>Are you sure you want to sign out</Body1>
    </Modal>
  );
};
