import { lazy, useContext, useEffect, useState } from "react";
import { TeamsFxContext } from "controller/contexts";
import { app } from "@microsoft/teams-js";
import { Spinner } from "@fluentui/react-components";
import {
  useAddCustomerUser,
  useGetCurrentCustomerUserUsingAadId,
  useGetSubscriptionStatus,
  useGetUserProfile,
  useGlobalStore,
  useMainStore,
} from "controller";
import { NavigationWrapper } from "Router";
import { useData } from "@microsoft/teamsfx-react";
import { getFullName } from "Utils";
import AccountWrapper from "./AccountWrapper";
import { SubscriptionExpireScreen } from "./SubscriptionExpireScreen";
import mixpanel from "mixpanel-browser";
export const Tab = () => {
  const { themeString, teamsUserCredential } = useContext(TeamsFxContext);
  const customerUserId = useGlobalStore((state) => state.userId);
  const token = useGlobalStore((state) => state.token);
  const apiToken = useGlobalStore((state) => state.token);
  const setProfileData = useGlobalStore((state) => state.setProfileData);
  const isSubscriptionAvailable = useMainStore(
    (state) => state.isSubscriptionAvailable
  );
  const { mutateAsync: addCustomerUser } = useAddCustomerUser();
  const { data: subscriptionStatusData, isLoading: isSubscriptionLoading } =
    useGetSubscriptionStatus(token);

  const setSubscriptionStatus = useMainStore(
    (state) => state.setSubscriptionStatus
  );
  const resetProfile = useGlobalStore((state) => state.resetProfileData);
  const {
    data: customerUserInfo,
    error: getProfileError,
    isError: isGetProfileError,
    isFetching: isFetchingProfile,
    isLoading: isLoadingProfile,
  } = useGetUserProfile(customerUserId);
  const [isError, setIsError] = useState(false);

  const { loading: loadingInfo, data: msTeamData } = useData(async () => {
    if (teamsUserCredential) {
      const userInfo = await teamsUserCredential.getUserInfo();
      return userInfo;
    }
  });
  const {
    data: userData,
    isLoading: loadingCurrentUserData,
    isFetched: isCurrentUserFetched,
  } = useGetCurrentCustomerUserUsingAadId(msTeamData?.objectId);

  useEffect(() => {
    if (!isFetchingProfile && !isLoadingProfile) {
      // console.log("🚀 ~ useEffect ~ msTeamData:", msTeamData);
      if (!loadingInfo && !msTeamData) {
        setIsError(true);
        return;
      }

      if (
        !loadingCurrentUserData &&
        isCurrentUserFetched &&
        (!userData ||
          (Array.isArray(customerUserInfo) &&
            (customerUserInfo.length === 0 ||
              userData.customerUserId !== customerUserInfo[0]?.mapping_id)))
      ) {
        // console.log("🚀 ~ Tab ~ reset:", userData);
        resetProfile();
        return;
      }
      if (Array.isArray(customerUserInfo) && customerUserInfo.length > 0) {
        setProfileData({
          displayName: getFullName({
            firstName: customerUserInfo[0]?.first_name,
            middleName: customerUserInfo[0]?.middle_name,
            lastName: customerUserInfo[0]?.last_name,
          }),
          profilePicture: customerUserInfo[0]?.profile_image || "",
          isPrivilaged:
            customerUserInfo[0]?.is_privileged_user === true ? true : false,
          email: customerUserInfo[0]?.email,
          msId: msTeamData?.objectId || "",
          isPermissionAllowed: Boolean(userData?.isPermissionAllowed),
        });

        addMixpanelData({
          customerUserData: {
            customerId: customerUserInfo[0]?.customer_id,
            customerUserId: customerUserInfo[0]?.mapping_id,
            email: customerUserInfo[0]?.email || "",
            mspId: customerUserInfo[0]?.msp_id,
            name: getFullName({
              firstName: customerUserInfo[0]?.first_name,
              middleName: customerUserInfo[0]?.middle_name,
              lastName: customerUserInfo[0]?.last_name,
            }),
            isPrivilaged:
              customerUserInfo[0]?.is_privileged_user === true ? true : false,
          },
        });
        setUpUserProfile();
        return;
      }
    }
    if (!isFetchingProfile && isGetProfileError) {
      setIsError(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    customerUserInfo,
    isGetProfileError,
    getProfileError,
    isFetchingProfile,
    isLoadingProfile,
    loadingInfo,
    userData,
    isCurrentUserFetched,
    loadingCurrentUserData,
  ]);

  useEffect(() => {
    if (isLoadingProfile || loadingCurrentUserData) {
      app.initialize();
    } else {
      app.notifySuccess();
    }
  }, [isLoadingProfile, loadingCurrentUserData]);

  useEffect(() => {
    if (subscriptionStatusData) {
      validateSubscriptionStatusData();
    }
  }, [subscriptionStatusData]);

  const validateSubscriptionStatusData = () => {
    const trialEndData = new Date(subscriptionStatusData.trial_end_date);
    const currentDate = new Date();
    //check trial end date
    // Check if the trial end date is in the future
    const isTrialActive = trialEndData > currentDate;
    if (isTrialActive) {
      return setSubscriptionStatus(true);
    } else {
      //check for the current_access_block_date
      if (subscriptionStatusData.current_access_block_date) {
        const currentAccessDate = new Date(
          subscriptionStatusData.current_access_block_date
        );
        const isActive = currentAccessDate > currentDate;
        if (isActive) {
          return setSubscriptionStatus(true);
        }
      }
      setSubscriptionStatus(false);
    }
  };

  const setUpUserProfile = async () => {
    try {
      await addCustomerUser({
        customerId: customerUserInfo[0]?.customer_id,
        customerUserId: customerUserInfo[0]?.mapping_id,
        microsoftId: msTeamData?.tenantId || "",
        mspId: customerUserInfo[0]?.msp_id,
        siteId: customerUserInfo[0]?.site_id,
        emailId: customerUserInfo[0]?.email || "",
        aadId: msTeamData?.objectId || "",
        token: apiToken,
        tenantId: msTeamData?.tenantId || "",
        displayName: getFullName({
          firstName: customerUserInfo[0]?.first_name,
          middleName: customerUserInfo[0]?.middle_name,
          lastName: customerUserInfo[0]?.last_name,
        }),
        profilePicture: customerUserInfo[0]?.profile_image || "",
      });
    } catch (e) {
      console.log("Unable to register user");
    }
  };

  return (
    // <ErrorBoundary fallback={<ErrorScreen />}>
    <div
      className={
        themeString === "default"
          ? "light"
          : themeString === "dark"
          ? "dark"
          : "contrast"
      }
    >
      {isLoadingProfile || loadingCurrentUserData || isSubscriptionLoading ? ( ///loadingInfo ||
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner style={{ margin: 100 }} />
        </div>
      ) : isError || apiToken.length <= 0 ? (
        <AccountWrapper
          userData={userData}
          msteamsData={msTeamData}
          loginCompleteHandler={() => {
            setIsError(false);
          }}
        />
      ) : !isSubscriptionAvailable ? (
        <SubscriptionExpireScreen />
      ) : (
        <NavigationWrapper />
      )}
    </div>
    // </ErrorBoundary>
  );
};

interface MixPanelUserData {
  name: string;
  email: string;
  mspId: string;
  customerId: string;
  customerUserId: string;
  isPrivilaged: boolean;
}
const addMixpanelData = ({
  customerUserData,
}: {
  customerUserData: MixPanelUserData;
}) => {
  mixpanel.identify(customerUserData.customerUserId);
  mixpanel.people.set({
    $email: customerUserData.email,
    $name: customerUserData.name,
    mspId: customerUserData.mspId,
    customerId: customerUserData.customerId,
    customerUserId: customerUserData.customerUserId,
    isPrivilaged: customerUserData.isPrivilaged,
  });
};
