import { useEffect } from "react";

import mixpanel from "mixpanel-browser";

let initialized = false;

if (!initialized) {
  mixpanel.init(
    (process.env.NODE_ENV !== "development"
      ? process.env.REACT_APP_MIXPANEL_TOKEN
      : "sd") || "",
    {
      debug: false,
      track_pageview: false,
      persistence: "localStorage",
    }
  );
  initialized = true;
}

export { mixpanel };
